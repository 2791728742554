import englishFlag from "../assets/icons/flags/en_flag.png";
import brFlag from "../assets/icons/flags/br_flag.svg";

//Maps the flags to the channels
export default function useFlags() {
  const langMap = {
    en: {
      channel: 1,
      src: englishFlag,
      code: "en",
      text: "English",
    },
    pt: {
      channel: 3,
      src: brFlag,
      code: "pt",
      text: "Português",
    },
  };

  return { langMap };
}
